// display properties
.d {
  &-none {
    display: none !important;
  }
  &-inline {
    display: inline !important;
  }
  &-inline-block {
    display: inline-block !important;
  }
  &-block {
    display: block !important;
  }
  &-table {
    display: table !important;
  }
  &-table-row {
    display: table-row !important;
  }
  &-table-cell {
    display: table-cell !important;
  }
  &-flex {
    display: flex !important;
  }
  &-inline-flex {
    display: inline-flex !important;
  }
}

// flex properties
.flex {
  &-row {
    flex-direction: row !important;
  }
  &-column {
    flex-direction: column !important;
  }
  &-row-reverse {
    flex-direction: row-reverse !important;
  }
  &-column-reverse {
    flex-direction: column-reverse !important;
  }

  &-wrap {
    flex-wrap: wrap !important;
  }
  &-nowrap {
    flex-wrap: nowrap !important;
  }
  &-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}

.justify-content {
  &-start {
    justify-content: flex-start !important;
  }
  &-end {
    justify-content: flex-end !important;
  }
  &-center {
    justify-content: center !important;
  }
  &-between {
    justify-content: space-between !important;
  }
  &-around {
    justify-content: space-around !important;
  }
}

.align-items {
  &-start {
    align-items: flex-start !important;
  }
  &-end {
    align-items: flex-end !important;
  }
  &-center {
    align-items: center !important;
  }
  &-baseline {
    align-items: baseline !important;
  }
  &-stretch {
    align-items: stretch !important;
  }
}

.align-content {
  &-start {
    align-content: flex-start !important;
  }
  &-end {
    align-content: flex-end !important;
  }
  &-center {
    align-content: center !important;
  }
  &-between {
    align-content: space-between !important;
  }
  &-around {
    align-content: space-around !important;
  }
  &-stretch {
    align-content: stretch !important;
  }
}

.align-self {
  &-auto {
    align-self: auto !important;
  }
  &-start {
    align-self: flex-start !important;
  }
  &-end {
    align-self: flex-end !important;
  }
  &-center {
    align-self: center !important;
  }
  &-baseline {
    align-self: baseline !important;
  }
  &-stretch {
    align-self: stretch !important;
  }
}
