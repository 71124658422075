@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap');
.timePage{
    background: url('../images/timer.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 200vh;
    .logoPart{
        .logoTitle{
            padding-top: 1%;
            padding-left: 5%;
            padding-right: 1%;
            font-size: 1.5rem;
            font-weight: bolder;
            font-family: 'Manrope', sans-serif;
            .LogoPic{
                filter: invert(100%);
                height: 30px;
                width: 30px;
            }
        }
    }
    .counter{
        padding-top: 10%;
        padding-bottom: 10%;
        width: 70%;
        padding-left: 5%;
        padding-right: 5%;
        h1{
            font-size: 4rem;
            font-weight: bolder;
            font-family: 'Manrope', sans-serif; 
            padding: 0;
            margin: 0;
        }
        .countdown {
            padding-left: 1%;
            padding-right: 1%;
            display: grid;
            column-gap: 0px;
            grid-template-columns: repeat(12, 2fr);
        }
        .counter{
            padding-top: 10%;
            padding-left: 5%;
            h1{
                padding: 0;
                margin: 0;
                font-size: 3.5rem;
                font-family: 'Manrope', sans-serif; 
                font-weight: bolder;
            }
        }
        ul{
            padding: 0;
            margin: 0;
        }
        .countdown li {
            padding: 1em;
            text-align: left;
            position: relative;
        }
        .countdown li + li:before {
            content: "";
            display: block;
            position: absolute;
            left: 5px;
        }
        .countdown .num {
            margin-bottom: 8px;
            font-size: 3rem;
            font-weight: bolder;
            font-family: 'Manrope', sans-serif; 
        }
        .countdown .txt {
            font-size: 1.5rem;
            font-family: 'Manrope', sans-serif; 
        }
    }
}

// .timer{
//     padding-left: 4%;
//     padding-right: 4%;
//     padding-top: 10%;
//     padding-bottom: 10%;
//     .countdown1
//     {
        
//         h1{
//             font-size: 6rem;
//             font-family: 'Manrope', sans-serif; 
//             font-weight: bolder;
//             text-shadow: 0px 1px, 1px 0px, 1px 0px;
//         }
//         .liveTimer{
//             font-size: 5rem;
            
//         }
//         .liveTimer1{
//             padding-top: 5%;
//             font-size: 1.5rem;
//         }
//     }
// }
ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}


@media screen and (max-width: 900px) {
    // .counter{
    //     background: url('../images/comingsoon.jpg');
    //     padding-top: 40%;
    //     padding-bottom: 40%;
    //     padding-left: 1%;
    //     padding-right: 1%;
    //     text-align: center;
    //     h1{
    //         font-size: 3rem;
    //     }
    //     .countdown{
    //         padding-left: 3%;
    //         padding-right: 3%;
    //         li{
    //             padding-left: 1.2em;
    //             .txt{
    //                 text-align: center;
    //             }
    //         }
    //     }
    // }
    .timePage{
        background: url('../images/timer1.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 100vh;
        .logoPart{
            .logoTitle{
                padding-top: 1%;
                padding-left: 5%;
                padding-right: 1%;
                font-size: 1.5rem;
                font-weight: bolder;
                font-family: 'Manrope', sans-serif;
                .LogoPic{
                    filter: invert(100%);
                }
            }
        }
        .counter{
            padding-top: 20%;
            padding-bottom: 20%;
            text-align: center;
            width: 100%;
            h1{
                font-size: 3rem;
                font-weight: bolder;
                font-family: 'Manrope', sans-serif; 
                padding: 0;
                margin: 0;
            }
            .countdown {
                display: grid;
                column-gap: 0px;
                grid-template-columns: repeat(12, 2fr);
            }
            .counter{
                padding-top: 10%;
                padding-left: 5%;
                h1{
                    padding: 0;
                    margin: 0;
                    font-size: 6rem;
                    font-family: 'Manrope', sans-serif; 
                    font-weight: bolder;
                }
            }
            ul{
                padding: 0;
                margin: 0;
            }
            .countdown li {
                padding: 0.7em;
                text-align: center;
                position: relative;
            }
            .countdown li + li:before {
                content: "";
                display: block;
                position: absolute;
                left: 5px;
            }
            .countdown .num {
                margin-bottom: 8px;
                font-size: 3rem;
                font-weight: bolder;
                font-family: 'Manrope', sans-serif; 
            }
            .countdown .txt {
                font-size: 1.5rem;
                font-family: 'Manrope', sans-serif; 
            }
        }
    }

    
}